import { createRouter, createWebHashHistory } from 'vue-router';
import { PortalStartupErrorType } from '@/Types/Enums/portalStartupErrorType';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import routes from '@/VueCore/routing/vueRoutes';

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
});

router.beforeEach((to, from, next) => {
  const contextData = useContextDataStore();
  contextData.disableElement = true;

  if (contextData.portalStartupError !== PortalStartupErrorType.None && to.path !== '/error') {
    next('/error');
  } else {
    next();
  }
});

router.afterEach(() => {
  const contextData = useContextDataStore();
  contextData.isLoading = false;
  contextData.disableElement = false;
});

export default router;