import axios, { AxiosRequestConfig } from 'axios';
import errorResponseHandler from '@/VueCore/services/clients/errorResponseHandler';
import tokenManager from '@/Utils/tokenManager';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import { ErrorCode } from '@/Types/Enums/errorCode';

export default class ApiClient {
  /**
   * Returns a promise for a GET request
   * @param {string} url
   * @param {ErrorCode[]} [wellKnownErrorCodes] Well-known error codes that if returned should not be logged
   * @return {Promise}
   */
  public static async get(url: string, wellKnownErrorCodes: ErrorCode[] = []): Promise<any> {
    try {
      const response = await axios.get(url, this.createAxiosConfig());
      return response.data;
    } catch (error: any) {
      const applicationError = errorResponseHandler.handleErrorResponse(error, wellKnownErrorCodes);
      throw applicationError;
    }
  }

  /**
   * Returns a promise for a PUT request. Put should be used to update a resource that already exists
   * @param {string} url
   * @param {*} data JavaScript object
   * @param {ErrorCode[]} [wellKnownErrorCodes] Well-known error codes that if returned should not be logged
   * @return {Promise}
   */
  public static async put(url: string, data: any, wellKnownErrorCodes: ErrorCode[] = []): Promise<any> {
    try {
      const response = await axios.put(url, data, this.createAxiosConfig(data));
      return response.data;
    } catch (error: any) {
      const applicationError = errorResponseHandler.handleErrorResponse(error, wellKnownErrorCodes);
      throw applicationError;
    }
  }

  /**
   * Returns a promise for a POST request. Post should be used to create a new resource
   * @param {string} url
   * @param {*} data JavaScript object
   * @param {ErrorCode[]} [wellKnownErrorCodes] Well-known error codes that if returned should not be logged
   * @return {Promise}
   */
  public static async post(url: string, data: any, wellKnownErrorCodes: ErrorCode[] = []): Promise<any> {
    try {
      const response = await axios.post(url, data, this.createAxiosConfig(data));
      return response.data;
    } catch (error: any) {
      const applicationError = errorResponseHandler.handleErrorResponse(error, wellKnownErrorCodes);
      throw applicationError;
    }
  }

  /**
   * Creates an Axios configuration object for the given parameters and handles adding the bearer token
   * HTTP header
   * @param {any} data Indicates if the request has data to be sent
   * @return {AxiosRequestConfig}
   */
  private static createAxiosConfig(data?: any): AxiosRequestConfig {
    const contextData = useContextDataStore();
    const config: AxiosRequestConfig = {
      headers: {
        'Accept-Language': contextData.languageCode,
        'Authorization': 'Bearer ' + tokenManager.getAccessToken()
      },
      timeout: contextData.inReleaseMode ? 45000 : undefined
    };

    if (data) {
      config.headers['Content-Type'] = 'application/json';
    }

    if (contextData.userData) {
      const personaId = contextData.userData.personaId;
      if (personaId) {
        config.headers['PersonaId'] = contextData.userData?.personaId;
      }
      const businessId = contextData.userData?.business?.businessId;
      if (businessId) {
        config.headers['BusinessId'] = contextData.userData?.business?.businessId;
      }
    }

    return config;
  }
}