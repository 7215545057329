import logger from '@/Utils/loggerVue';
import redirectHelper from '@/Utils/redirectHelper';
import { ApplicationError } from '@/VueCore/services/clients/applicationError';
import { ErrorCode } from '@/Types/Enums/errorCode';

/**
 * This module is used to inspect error responses from Axios calls and handle known scenarios
 */
class ErrorResponseHandler {
  /**
   * Inspect the error response for an unauthorized response or error response with translated error
   * messages and handle as appropriately.
   * @param {any} serverError
   * @param {ErrorCode[]} [wellKnownErrorCodes] Well-known error codes that if returned should not be logged
   * @return {ServerError}
   */
  public handleErrorResponse(serverError: any, wellKnownErrorCodes: ErrorCode[]): ApplicationError {

    const applicationError = this.parseServerError(serverError);
    this.handleUnauthorisedResponse(applicationError);
    this.handleForbiddenResponse(applicationError, wellKnownErrorCodes);
    this.handleTimeoutError(applicationError);
    this.handleRedirectResponse(applicationError);
    return applicationError;
  }

  /**
   * Parse the Axios error and initialize a new ServerError
   * @param {AxiosError} error
   * @return {ServerError}
   */
  public parseServerError(error: any): ApplicationError {
    return new ApplicationError(error);
  }

  /**
   * Redirect user to login page if 401 returned as the user's token has expired
   * @param {ApplicationError} applicationError
   */
  private handleUnauthorisedResponse(applicationError: ApplicationError): void {
    if (applicationError.innerError?.status === 401) {
      redirectHelper.tokenLoginRedirect();
    }
  }

  /**
   * Log a warning if 403 returned as the user's accesses forbidden resource
   * @param {ApplicationError} applicationError
   * @param {ErrorCode[]} [wellKnownErrorCodes] Well-known error codes that if returned should not be logged
   */

  private handleForbiddenResponse(applicationError: ApplicationError, wellKnownErrorCodes: ErrorCode[]): void {
    if (applicationError.innerError?.status === 403) {

      // If the caller has not specified to ignore this error code then log
      if (wellKnownErrorCodes.length === 0 || !wellKnownErrorCodes.includes(applicationError.errorData.errorCode)) {
        logger.logForbiddenAccess();
        applicationError.isLogged = true;
      }
    }
  }

  /**
   * Log specific warning if an Axios request timed out
   * @param {ApplicationError} applicationError
   */
  private handleTimeoutError(applicationError: ApplicationError): void {
    if (applicationError.innerError.code === 'ECONNABORTED') {
      logger.logTimeout();
    }
  }

  /**
   * Handle redirect response
   * @param {ApplicationError} applicationError
   */
  private handleRedirectResponse(applicationError: ApplicationError): void {
    if (applicationError.innerError?.status === 302) {
      // redirect to home
      redirectHelper.redirectToPath('');
    }
  }
}

export default new ErrorResponseHandler();
