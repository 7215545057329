import $ from 'jquery';
import errorResponseHandler from './errorResponseHandler';
import tokenManager from '../Utils/tokenManager';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';

function RepositoryHelper() {

  const self = this;

  /**
   * Returns a promise for a GET AJAX request
   * @param {string} url
   * @param {ErrorCode[]} [errorCodesToIgnore] Well-known error codes that if returned should not be logged
   * @return {Promise}
   */
  self.ajaxGet = function (url, errorCodesToIgnore = []) {
    return new Promise(function (resolve, reject) {
      $.ajax(createAjaxSetting(url)).then(resolve, function (jqXhr) {
        errorResponseHandler.handleErrorResponse(jqXhr, errorCodesToIgnore);
        reject(jqXhr);
      });
    });
  };

  /**
   * Returns a promise for a PUT AJAX request. Put should be used to update a resource that already exists
   * @param {string} url
   * @param {*} data JavaScript object
   * @param {ErrorCode[]} [errorCodesToIgnore] Well-known error codes that if returned should not be logged
   * @return {Promise}
   */
  self.ajaxPut = function (url, data, errorCodesToIgnore = []) {
    return new Promise(function (resolve, reject) {
      $.ajax(createAjaxSetting(url, 'PUT', data)).then(resolve, function (jqXhr) {
        errorResponseHandler.handleErrorResponse(jqXhr, errorCodesToIgnore);
        reject(jqXhr);
      });
    });
  };

  /**
 * Returns a promise for a POST AJAX request. Post should be used to create a new resource
 * @param {string} url
 * @param {*} data JavaScript object
 * @param {ErrorCode[]} [errorCodesToIgnore] Well-known error codes that if returned should not be logged
 * @return {Promise}
 */
  self.ajaxPost = function (url, data, errorCodesToIgnore = []) {
    return new Promise(function (resolve, reject) {
      $.ajax(createAjaxSetting(url, 'POST', data)).then(resolve, function (jqXhr) {
        errorResponseHandler.handleErrorResponse(jqXhr, errorCodesToIgnore);
        reject(jqXhr);
      });
    });
  };


  /**
* Returns a promise for a POST AJAX request. Post should be used to create a new resource with custom data type
* @param {string} url
* @param {*} data JavaScript object
* @param {ErrorCode[]} [errorCodesToIgnore] Well-known error codes that if returned should not be logged
* @param {string} responseType response data type. Defaults to 'application/json'
* @return {Promise}
*/
  self.ajaxPostBinaryData = function (url, data, errorCodesToIgnore = []) {
    return new Promise(function (resolve, reject) {
      const settings = createAjaxSetting(url, 'POST', data);
      settings.dataType = 'binary';
      $.ajax(settings).then(resolve, function (jqXhr) {
        errorResponseHandler.handleErrorResponse(jqXhr, errorCodesToIgnore);
        reject(jqXhr);
      });
    });
  };

  /**
   * Returns a promise for a Delete AJAX request.
   * @param {string} url
   * @param {ErrorCode[]} [errorCodesToIgnore] Well-known error codes that if returned should not be logged
   * @return {Promise}
   */
  self.ajaxDelete = function (url, errorCodesToIgnore = []) {
    return new Promise(function (resolve, reject) {
      $.ajax(createAjaxSetting(url, 'DELETE')).then(resolve, function (jqXhr) {
        errorResponseHandler.handleErrorResponse(jqXhr, errorCodesToIgnore);
        reject(jqXhr);
      });
    });
  };

  /**
  * Executes a download post request for the given parameters and handles adding the access token, the accept language, Persona ID and Business ID.
  * HTTP header
  * @param {string} url
  * @param {object} [params]
  */
  self.downloadPostRequest = function (url, params) {
    const contextData = useContextDataStore();
    url += `?access_token=${tokenManager.getAccessToken()}`;
    params = Object.assign({
      AcceptLanguage: contextData.languageCode,
      PersonaId: contextData.userData.personaId,
      BusinessId: contextData.userData.business.businessId
    }, params);

    // Build a form
    const form = $('<form></form>').attr('action', url).attr('method', 'post').attr('target', '_blank');
    // Add key/value params
    $.each(params, function (key, value) {
      form.append($('<input></input>').attr('type', 'hidden').attr('name', key).attr('value', value));
    });

    // send request
    form.appendTo('body').submit().remove();
  };

  /**
   * Creates an jQuery AJAX setting object for the given parameters and handles adding the bearer token
   * HTTP header
   * @param {string} url
   * @param {string} [type]
   * @param {*} [data]
   * @return {*}
   */
  function createAjaxSetting(url, type, data) {
    const contextData = useContextDataStore();

    const settings = {
      url: url,
      type: type || 'GET',
      headers: {
        'Accept-Language': contextData.languageCode
      },
      beforeSend: function (jqXhr) {
        jqXhr.setRequestHeader('Authorization', 'Bearer ' + tokenManager.getAccessToken());

        if (contextData.userData) {
          jqXhr.setRequestHeader('PersonaId', contextData.userData.personaId);
          jqXhr.setRequestHeader('BusinessId', contextData.userData.business.businessId);
        }
      }
    };

    if (data) {
      settings.data = JSON.stringify(data);
      settings.contentType = 'application/json';
    }

    // In release mode, once the SPA has loaded, expect all AJAX requests to return within 45 seconds. In
    // development, don't have a timeout to cater for debugging and long running breakpoints. While the SPA
    // is loading, "contextData.inReleaseMode" will be null until the portal settings are retrieved.
    if (!contextData.inReleaseMode) {
      settings.timeout = 45000;
    }

    return settings;
  }

  // ajaxTransport for binary content type needed to display PDF
  $.ajaxTransport('+binary', (options, originalOptions, jqXhr) => {
    // check for conditions and support for blob / arraybuffer response type
    if (window.FormData && (options.dataType && options.dataType === 'binary' || options.data &&
      (window.ArrayBuffer && options.data instanceof ArrayBuffer ||
        window.Blob && options.data instanceof Blob))) {
      return {
        // create new XMLHttpRequest
        send: (headers, callback) => {
          // setup all variables
          const xhr = new XMLHttpRequest();
          const url = options.url;
          const type = options.type;
          const async = options.async || true;

          // blob or arraybuffer. Default is blob
          const dataType = options.responseType || 'blob';
          const data = options.data || null;

          xhr.addEventListener('load', () => {
            const data = {};
            data[options.dataType] = xhr.response;

            // error returned as JSON but AJAX keeps it in blob with data type application/json
            if (xhr.response instanceof Blob && xhr.response.type === 'application/json') {
              xhr.response.text().then(jsonString => {
                callback(xhr.status, xhr.statusText, { json: JSON.parse(jsonString) }, xhr.getAllResponseHeaders());
              });
            } else {
              // make callback and send data
              callback(xhr.status, xhr.statusText, data, xhr.getAllResponseHeaders());
            }
          });

          xhr.open(type, url, async);

          // setup custom headers
          for (const i in headers) {
            if (Object.prototype.hasOwnProperty.call(headers, i)) {
              xhr.setRequestHeader(i, headers[i]);
            }
          }

          xhr.responseType = dataType;
          xhr.send(data);
        },
        abort: () => {
          jqXhr.abort();
        }
      };
    }
    // ReSharper disable once NotAllPathsReturnValue
  });
}

export default new RepositoryHelper();
