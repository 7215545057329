import { ApplicationError } from '@/VueCore/services/clients/applicationError';
import { PortalStartupErrorType } from '@/Types/Enums/portalStartupErrorType';
import AuthenticationSettings from '@/Types/authenticationSettings';
import ContextState from '@/VueCore/stores/Models/contextState';
import config from '@/config';
import logger from '@/Utils/loggerVue';
import portalStartup from '@/Loaders/portalStartup';
import redirectHelper from '@/Utils/redirectHelper';
import systemSettingsService from '@/VueCore/services/systemSettingsService';
import tokenManager from '@/Utils/tokenManager';

class PortalAuthentication {

  initialise(): Promise<ContextState> {
    return systemSettingsService.getAuthenticationSettings()
      .then(authenticationSettings => this.checkTokenIsValid(authenticationSettings))
      .catch((error: any) => {
        const applicationError = error as ApplicationError;
        logger.logAndThrowUntranslatedError(
          'An error occurred loading the site. Please try again later.',
          'Unable to retrieve the authentication settings and check the security token.',
          applicationError
        );
        const contextData: ContextState = new ContextState();
        contextData.portalStartupError = PortalStartupErrorType.UnknownError;
        return Promise.resolve(contextData);
      });
  }

  private checkTokenIsValid(authenticationSettings: AuthenticationSettings): Promise<ContextState> {
    const tokenManagerSettings = config.getTokenManagerSettingsForLogin(authenticationSettings);
    tokenManagerSettings.prompt = 'login';

    return tokenManager.initAsync(tokenManagerSettings)
      .then(authTokenLanguageDetails => {
        if (tokenManager.validTokenExists()) {
          return portalStartup.init(authTokenLanguageDetails) as Promise<ContextState>;
        } else {
          redirectHelper.tokenLoginRedirect();
        }
      })
      .catch((err: any) => {
        console.error('An error occurred initializing the token manager', err);
        return Promise.reject(err);
      });
  }
}

export default new PortalAuthentication();
