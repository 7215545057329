<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';

import FlowWrapper from '@/Utils/flowWrapper';
import HeaderAccountType from '@/VueComponents/SiteComponents/HeaderAccountType.vue';
import HelpNavItem from '@/VueComponents/SiteComponents/HelpNavItem.vue';
import UploadingNavItem from '@/VueComponents/SiteComponents/UploadingNavItem.vue';
import LanguageNavItemPortal from '@/VueComponents/SiteComponents/LanguageNavItemPortal.vue';
import LoadingData from '@/VueComponents/PageComponents/Portal/LoadingData.vue';
import TopNav from '@/VueComponents/SiteComponents/TopNav.vue';
import UserNavItem from '@/VueComponents/SiteComponents/UserNavItem.vue';
import SessionExpiredOverlay from '@/VueComponents/SiteComponents/SessionExpiredOverlay.vue';
import resourceHelper from '@/Utils/resourceHelper';
import { ApplicationError } from '@/VueCore/services/clients/applicationError';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import TokenExpTimer from '@/Utils/tokenExpTimer';
import logger from '@/Utils/loggerVue';
import uploadManager from '@/Utils/uploadManager';
import currentUserService from '@/VueCore/services/currentUserService';

const isDataLoadedSuccessfully = ref(false);
const ariaLiveNavigationText = ref('');
const contextData = useContextDataStore();
const pageTitle = resourceHelper.getString('PortalSpaWebpageTitle');
const route = useRoute();

onMounted(async () => {
  // remove binding failure fallback loader/error divs
  const fallbackScreensElement = document.getElementById('fallbackScreens');
  fallbackScreensElement?.parentNode.removeChild(fallbackScreensElement);

  const staticHeader = document.getElementById('static-header');
  if (staticHeader) {
    staticHeader.style.display = 'none';
  }

  FlowWrapper.init();

  TokenExpTimer.initAuthTokenExpirationTimer(() => {
    if (uploadManager.uploadsCount()) {
      contextData.sessionExpiredOverlayVisible = true;
    }
  });

  isDataLoadedSuccessfully.value = contextData.portalStartupError === 'None';

  try {
    if (contextData.languageCode.toLowerCase() !== contextData.userData.languageDetails.languageCode.toLowerCase()) {
      currentUserService.updateLanguageCode(contextData.languageCode);
    }
  } catch (error: any) {
    const applicationError = new ApplicationError(error);
    if (!applicationError.isLogged) {
      logger.error('UnexpectedErrorWhileUpdatingUserLanguage', null, applicationError);
    }
  }

  watch(() => route.meta.pageTitleKey, newTitle => {
    if (contextData.portalStartupError === 'None') {
      ariaLiveNavigationText.value = `${resourceHelper.getString('NavigatingToPage')} ${resourceHelper.getString(route.meta.pageTitleKey)}`;

      if (route.meta.pageTitleKey) {
        document.title = resourceHelper.getString(route.meta.pageTitleKey) + ' | ' + pageTitle;
      }
    } else {
      ariaLiveNavigationText.value = contextData.portalStartupError;
      document.title = route.meta.pageTitleKey + ' |  Community Portal';
    }
  });

});

</script>
<template>
  <header
    class="navbarbanner"
    role="banner"
  >
    <h1 class="sub-title mt-2">
      <a href="/">
        <img
          src="../../../wwwroot/Content/images/logo/evidencentral.svg"
          height="38"
          alt="Evidencentral Logo"
        >
      </a>
      <HeaderAccountType v-if="isDataLoadedSuccessfully" />
    </h1>

    <div
      v-if="isDataLoadedSuccessfully"
      class="dropdowns-wrapper"
    >
      <UploadingNavItem />
      <LanguageNavItemPortal />
      <UserNavItem />
      <HelpNavItem />
    </div>
  </header>

  <nav
    class="navbar navbar-inverse"
    role="navigation"
  >
    <div class="navbar-header">
      <button
        type="button"
        class="navbar-toggle"
        data-toggle="collapse"
        data-target=".navbar-collapse"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar" />
        <span class="icon-bar" />
        <span class="icon-bar" />
      </button>
    </div>

    <TopNav v-if="isDataLoadedSuccessfully" />
  </nav>

  <main role="main">
    <!-- Accessibility: Live region to announce SPA page component change / navigation -->
    <div
      class="sr-only"
      aria-live="polite"
    >
      {{ ariaLiveNavigationText }}
    </div>

    <!--When the SPA initally loads, if there are "resolve" promises for the route, set the intial page to
        "loading-data" to display an animation to the user until the promises are resolved.-->
    <LoadingData v-if="contextData.isLoading" />
    <router-view
      v-else
      v-disable-element="contextData.disableElement"
    />
  </main>

  <br>

  <div id="fallbackScreens">
    <br>
    <br>
    <br>

    <div
      id="preloaderScreen"
      class="col-md-6 col-md-offset-3 text-center"
      style="display: none"
    >
      <div class="cbp-loader" />
      <h3>Loading... Please wait</h3>
    </div>

    <div
      id="fatalErrorScreen"
      class="col-md-10 col-md-offset-1"
      style="display: none"
    >
      <h2>There was a problem loading the site</h2>
      <p class="lead">
        Please try again later
      </p>
    </div>
  </div>
  <SessionExpiredOverlay v-if="contextData.sessionExpiredOverlayVisible" />
</template>