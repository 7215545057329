import { defineStore } from 'pinia';
import localStorageHelper from '@/Utils/localStorageHelper';
import ContextState from '@/VueCore/stores/Models/contextState';
import { PortalStartupErrorType } from '@/Types/Enums/portalStartupErrorType';

export const useContextDataStore = defineStore('contextData', {
  // Pinia throws warning in case if using new ContextData().
  // To reduce the number of warnings the state is initialised as a plain object.
  state: (): ContextState => ({
    languageCode: 'en-US',
    availableLanguages: [],
    calendarDateTimeFormat: '',
    inReleaseMode: false,
    resourceData: {},
    userData: {},
    portalSettings: {},
    registrationData: {},
    isLoading: false,
    disableElement: false,
    portalStartupError: PortalStartupErrorType.None,
    sessionExpiredOverlayVisible: false
  }
  ),
  actions: {
    setContextData(data: Partial<ContextState>) {
      Object.assign(this, data);
    }
  },
  getters: {
    flagIcon: (state: ContextState) => {
      const parts = state.languageCode.split('-');
      const countryCode = parts.length === 1 ? parts[0] : parts[1];
      return 'Content/images/flags/' + countryCode.toLowerCase() + '.svg';
    }
  },
  watch: {
    languageCode(newValue: string) {
      // Watch for changes in languageCode and update the <html> lang attribute
      document.documentElement.lang = newValue;

      // Sync the language code with local storage so when a user returns back to the Business Portal
      // but isn't yet authenticated, we can use the language code they previously had.
      localStorageHelper.setLanguageDetails(newValue);
    }
  }
});