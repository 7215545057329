import UploadModel from '@/Models/uploadModel';
import { UploadType } from '@/Types/Enums/uploadType';
import BasePartnerUploadDataModel from '@/Models/basePartnerUploadDataModel';

export default class PartnerUploadModel implements BasePartnerUploadDataModel {
  uploadId: string;
  requestId: string;
  uploadToken: string;
  addedTimestamp: string;
  fileName: string;
  size: number;
  fileRelativePath: string;
  personaDisplayName: string;

  rootFolderName: string;
  comments: string;
  nativeBrowserFile: File;
  ajaxRequest: any;

  uploadStatuses: string[];
  type: UploadType;

  cameraName: string;
  statusName: string;
  progress: number;
  bytesUploaded: number;
  userIsCurrentlyUploading: boolean;

  isAuthorising: boolean;

  uploadManagerUploadModel: UploadModel;
  hasUploadInfoChanged: boolean = false;
  isFolder: boolean = false;

  getSortingValue(fieldName: string) {
    switch (fieldName) {
      case 'addedTimestamp':
        return this.addedTimestamp;
      case 'fileName':
        return this.fileName;
      case 'size':
        return this.size;
      case 'statusName':
        return this.statusName;
      case 'userName':
        return this.personaDisplayName;
      case 'path':
        return this.fileRelativePath;
    }
  }

  getIds() {
    return [this.uploadId];
  }

  getStatusNames(): string[] {
    return [this.statusName];
  }

  getUploadModels() {
    return [this.uploadManagerUploadModel];
  }

  tryRemoveFromTheFolderById(id: string): boolean {
    // Nothing to remove, upload is not a folder
    return false;
  }

  getUploadsLength() {
    // Single upload model has only one upload
    return 1;
  }

  getFolderUploads(): BasePartnerUploadDataModel[] {
    // Return empty array since upload is not a folder
    return [];
  }

}