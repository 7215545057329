import 'core-js/stable'; // polyfills for ECMAScript up to 2020
import 'regenerator-runtime/runtime'; // Standalone runtime for Regenerator-compiled generator and async functions
import 'bootstrap-sass'; // Include Bootstrap JS

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import PrimeVueSettingsManager from '@/VueCore/utils/primeVueSettingsManager';
import resourcePlugin from '@/VueCore/plugins/resourcePlugin';
import directivesPlugin from '@/VueCore/plugins/directivesPlugin';
import App from '@/VueEntryPoints/PortalEntry.vue';
import localStorageHelper from '@/Utils/localStorageHelper';
import { LocalStorageKeyType } from '@/Types/Enums/localStorageKeyType';
import router from '@/VueCore/routing/vueRouter';
import portalAuthentication from '@/Loaders/portalAuthentication';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';

/**
 * Initialising Vue Main Portal Entry SPA.
 */

initialise();
function initialise() {
  const app = createApp(App);
  const pinia = createPinia();
  app.use(pinia);

  // Clear a registration ID that may exist as it's not applicable when logging in to the portal (multiple users using same machine)
  localStorageHelper.clearSessionValue(LocalStorageKeyType.AuthenticationRegistrationId);

  // featching all the required data before initializing Vue router
  portalAuthentication.initialise()
    .then(contextData => {
      console.info(contextData.languageCode);
      // Update the Pinia store with data received from contextData
      const contextDataStore = useContextDataStore();
      contextDataStore.setContextData(contextData);
      PrimeVueSettingsManager.getSettings(contextData.languageCode)
        .then(settings => {
          app.use(PrimeVue, settings);
          app.use(directivesPlugin);
          app.use(resourcePlugin);
          app.use(router);
          app.mount('#app');
        });
    });
}
