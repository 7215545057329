import ko from 'knockout';
import constants from '../constants';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import uploadFileLimitResolver from '@/Utils/uploadFileLimitResolver';
import UploadTypeResolver from '@/Utils/uploadTypeResolver';
import FileListConverter from '@/Utils/fileListConverter';
import { UploadOption } from '@/Types/Enums/uploadOption';
import { UploadType } from '@/Types/Enums/uploadType';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';
import logger from '@/Utils/logger';
import resourceHelper from '@/Utils/resourceHelper';
import UploadModel from '@/Models/uploadModel';
import LimitInfo from '@/Types/limitInfo';
import BasePartnerUploadDataModel from '@/Models/basePartnerUploadDataModel';

// TODO: To retire UploadModel when uploadManager is migrated.
export default class RequestUploadValidation {

  /**
   * @typedef {Object} resourceInfo
   * @property {string} key The key which is presented in the BusinessPortal.Resources project.
   * @property {Object} [params] A set of parameters used in resource string.
   */
  /**
   * Checks if the uploads for a request are in the correct state to allow the request to be submitted.
   * If the validation fails, a resourceInfo object for the failure message to display to the user is returned
   * else if everything is OK, null is returned.
   * @param {(BasePartnerUploadDataModel)[]} uploads Array of UploadModel for each file that already exists
   * @param {bool} areUploadsOptional Flag indicates whether at least one uploaded file is required
   * @return {string | null}
   */
  public static getStatusValidationResourceInfo(
    uploads: BasePartnerUploadDataModel[],
    areUploadsOptional = false): string | null {

    if (uploads.length === 0) {
      if (!areUploadsOptional) {
        return resourceHelper.getString('NoFilesUploaded');
      }

      return null;
    }

    const statuses = uploads.flatMap(function (upload) {
      return upload.getStatusNames();
    });

    const containsAuthorising = statuses.indexOf(constants.uploadStatuses.authorising) > -1;
    const containsPending = statuses.indexOf(constants.uploadStatuses.pending) > -1;
    const containsUploading = statuses.indexOf(constants.uploadStatuses.uploading) > -1;
    const containsFailed = statuses.indexOf(constants.uploadStatuses.failed) > -1 ||
      statuses.indexOf(constants.uploadStatuses.failedAuthorisation) > -1;

    if (containsAuthorising || containsPending || containsUploading) {
      return resourceHelper.getString('UploadsStillInProgress');
    }

    if (containsFailed) {
      return resourceHelper.getString('FailedUploadsExist');
    }

    return null;
  }

  public static getStatusValidationResourceInfoObsolete(
    uploads: UploadModel[],
    areUploadsOptional = false): { key: string, params?: any } | null {

    if (uploads.length === 0) {
      if (!areUploadsOptional) {
        return { key: 'NoFilesUploaded' };
      }

      return null;
    }

    const statuses = uploads.map(function (upload) {
      return upload.statusName();
    });

    const containsAuthorising = statuses.indexOf(constants.uploadStatuses.authorising) > -1;
    const containsPending = statuses.indexOf(constants.uploadStatuses.pending) > -1;
    const containsUploading = statuses.indexOf(constants.uploadStatuses.uploading) > -1;
    const containsFailed = statuses.indexOf(constants.uploadStatuses.failed) > -1 ||
      statuses.indexOf(constants.uploadStatuses.failedAuthorisation) > -1;

    if (containsAuthorising || containsPending || containsUploading) {
      return { key: 'UploadsStillInProgress' };
    }

    if (containsFailed) {
      return { key: 'FailedUploadsExist' };
    }

    return null;
  }

  /**
   * Perform validation to check if new uploads can be added. If the validation fails, a resourceInfo object for
   * the failure message to display to the user is returned else if everything is OK, null is returned.
   * @param {FileList} fileList Native browser FileList object for files to add
   * @param {(BasePartnerUploadDataModel)[]} uploads Array of partner uploads for each file that already exists
   * @return {string|null}
   */
  public static getAddUploadsValidationResourceInfo(
    fileList: FileList,
    uploads: BasePartnerUploadDataModel[]): string | null {

    if (fileList.length === 0) {
      return resourceHelper.getString('UploadNoFilesFound');
    }

    if (RequestUploadValidation.containsZeroSizeItems(fileList)) {
      return resourceHelper.getString('EmptyFoldersAndFilesNotAllowed');
    }

    if (RequestUploadValidation.isCctvFolderDuplicated(fileList, uploads.flatMap(u => u.getUploadModels()))) {
      return resourceHelper.getString('UploadFolderAlreadyExists');
    }
    const contextData = useContextDataStore();
    const maxFileSize = contextData.portalSettings.maximumUploadSize;
    if (RequestUploadValidation.doAnyFileExceedAllowedSize(FileListConverter.fileListToArray(fileList), maxFileSize)) {
      const formattedFileSize = RequestUploadValidation.getFormattedFileSize(maxFileSize);
      return resourceHelper.getString('MaxFileSizeExcess', { maxFileSize: formattedFileSize });
    }

    const maxCctvFolderUploadSize = contextData.portalSettings.maximumCctvFolderUploadSize;
    if (RequestUploadValidation.isCctvFolderExceedsMaximumSize(fileList, maxCctvFolderUploadSize)) {
      const formattedCctvFolderSize = RequestUploadValidation.getFormattedFileSize(maxCctvFolderUploadSize);
      return resourceHelper.getString('MaxFolderSizeExcess', { maxFolderSize: formattedCctvFolderSize });
    }

    return null;
  }

  /**
   * Perform validation to check if new uploads can be added. If the validation fails, a resourceInfo object for
   * the failure message to display to the user is returned else if everything is OK, null is returned.
   * @param {FileList} fileList Native browser FileList object for files to add
   * @param {UploadModel[]} uploads Array of UploadModel for each file that already exists
   * @return {resourceInfo|null}
   */
  public static getAddUploadsValidationResourceInfoObsolete(
    fileList: FileList,
    uploads: UploadModel[]): { key: string, params?: any } | null {

    if (fileList.length === 0) {
      return { key: 'UploadNoFilesFound' };
    }

    if (RequestUploadValidation.containsZeroSizeItems(fileList)) {
      return { key: 'EmptyFoldersAndFilesNotAllowed' };
    }

    if (RequestUploadValidation.isCctvFolderDuplicated(fileList, uploads)) {
      return { key: 'UploadFolderAlreadyExists' };
    }
    const contextData = useContextDataStore();
    const maxFileSize = contextData.portalSettings.maximumUploadSize;
    if (RequestUploadValidation.doAnyFileExceedAllowedSize(FileListConverter.fileListToArray(fileList), maxFileSize)) {
      const formattedFileSize = RequestUploadValidation.getFormattedFileSize(maxFileSize);
      return { key: 'MaxFileSizeExcess', params: { maxFileSize: formattedFileSize } };
    }
    const maxCctvFolderUploadSize = contextData.portalSettings.maximumCctvFolderUploadSize;
    if (RequestUploadValidation.isCctvFolderExceedsMaximumSize(fileList, maxCctvFolderUploadSize)) {
      const formattedCctvFolderSize = RequestUploadValidation.getFormattedFileSize(maxCctvFolderUploadSize);
      return { key: 'MaxFolderSizeExcess', params: { maxFolderSize: formattedCctvFolderSize } };
    }

    return null;
  }

  /**
   * Returns upload limit info for UploadLimitModal messages
   * @param {UploadParentItemType} itemType Request/response item type
   * @param {FileList} fileList Native browser FileList object for files to add
   * @param {number} uploads Array of UploadModel for each file that already exists
   * @return {LimitInfo}
   */
  public static getUploadLimit(itemType: UploadParentItemType, fileList: FileList, uploads: number): LimitInfo {
    const limitInfo = uploadFileLimitResolver.createLimitInfo(uploadFileLimitResolver.getUploadFileLimitByRequestType(itemType),
      uploads, fileList.length, uploads + fileList.length);
    return limitInfo;
  }

  /**
   * Returns file duplicates for single files or files from folders
   * @param {FileList} fileList Native browser FileList object for files to add
   * @param {UploadModel[]} uploads Array of UploadModel for each file that already exists
   * @param {UploadParentItemType} uploadParentItemType Type of entity owned uploads (request or self-response)
   * @return {Object<File[], File[]>}
   */
  public static getFileDuplicates(fileList: FileList, uploads: UploadModel[],
    uploadParentItemType: UploadParentItemType
  ):{ duplicates: File[], notDuplicates: File[] } {
    const filesToAdd = FileListConverter.fileListToArray(fileList);

    const duplicates = [];
    const notDuplicates = [];
    ko.utils.arrayForEach(filesToAdd, file => {
      const isDuplicate = uploads.some(existedUpload => {
        if (existedUpload.type === UploadTypeResolver.getTypeFromNativeFile(file)) {
          if (file.webkitRelativePath) {
            return existedUpload.fileRelativePath === file.webkitRelativePath;
          } else {
            return existedUpload.fileName === file.name;
          }
        }

        return false;
      });
      if (isDuplicate) {
        duplicates.push(file);
      } else {
        notDuplicates.push(file);
      }
    });

    return {
      duplicates,
      notDuplicates
    };
  }

  /**
   * Check if upload option is zip
   * @param {string} uploadOption
   * @return {boolean}
   */
  public static isZipOptionSelected(uploadOption: UploadOption): boolean {
    return uploadOption === UploadOption.selectZipFiles;
  }

  /**
   * Check if upload option is folder
   * @param {string} uploadOption
   * @return {boolean}
   */
  public static isFolderOptionSelected(uploadOption: UploadOption): boolean {
    return uploadOption === UploadOption.selectFolder;
  }

  /**
   * Check if upload option is file
   * @param {string} uploadOption
   * @return {boolean}
   */
  public static isFileOptionSelected(uploadOption: UploadOption): boolean {
    return uploadOption === UploadOption.selectFiles;
  }

  /**
   * Check if file is zip
   * @param {string} fileName
   * @return {boolean}
   */
  public static isFileZip(fileName: string): boolean {
    return fileName.split('.').pop() === 'zip';
  }

  /**
   * Returns file zips for single files or files from folders
   * @param {File[]} fileList
   * @return {File[]}
   */
  public static getZipFiles(fileList: FileList): File[] {
    return Array.from(fileList).filter(f => RequestUploadValidation.isFileZip(f.name));
  }

  /**
   * Returns non-zip files and upload option selected as zip.
   * @param {File[]} fileList
   * @return {File[]}.
   */
  public static getNonZipFiles(fileList: FileList): File[] {
    return Array.from(fileList).filter(f => !RequestUploadValidation.isFileZip(f.name));
  }

  /**
   * Check if zip files contains for single files or files from folders
   * @param {File[]} fileList Native browser FileList object
   * @return {boolean}
   */
  public static containsZipFiles(fileList: FileList): boolean {
    return RequestUploadValidation.getZipFiles(fileList).length > 0;
  }

  /**
   * Check if selected files contains non-zip files and upload option selected as zip.
   * @param {string} uploadOption Type of selected file
   * @param {number} nonZipFilesCount Non-zip files count
   * @return {boolean}
   */
  public static showContainsNonZipWarning(uploadOption: UploadOption, nonZipFilesCount: number): boolean {
    return uploadOption === UploadOption.selectZipFiles && nonZipFilesCount > 0;
  }

  /**
   * Check if selected files contains zip files and upload option selected as folder/files.
   * @param {string} uploadOption Type of selected file
   * @param {File[]} fileList Native browser FileList object
   * @return {boolean}
   */
  public static showContainsZipWarning(uploadOption: UploadOption, fileList: FileList): boolean {
    return (uploadOption === UploadOption.selectFiles && fileList?.length > 1 &&
        RequestUploadValidation.getZipFiles(fileList).length > 0) ||
        (uploadOption === UploadOption.selectFolder && RequestUploadValidation.containsZipFiles(fileList));
  }

  /**
   * Check if selected file is folder/zip or if single zip file.
   * @param {string} uploadOption Type of selected file
   * @param {File[]} fileList Native browser FileList object
   * @return {boolean}
   */
  public static showContentTypeSelectionModal(uploadOption: UploadOption, fileList: FileList): boolean {
    return !(uploadOption === UploadOption.selectFiles) ||
      (uploadOption === UploadOption.selectFiles && fileList.length === 1 && RequestUploadValidation.getZipFiles(fileList).length === 1);
  }

  /**
   * Check if request or self-response is completing and show warning to not allow upload.
   * @param {boolean} uploadParentItemIsCompleting Item is in process of completing/submitting
   * @param {string} uploadParentItemType Parent item type (e.g. UploadParentItemType.selfResponse)
   * @return {boolean}
   */
  public static showItemIsCompletingWarning(uploadParentItemIsCompleting: boolean | undefined,
    uploadParentItemType: UploadParentItemType): boolean {
    if (uploadParentItemIsCompleting === true) {
      logger.warning(uploadParentItemType === UploadParentItemType.partnerRequest ?
        'UploadIsNotAllowedForSubmittedRequest' : 'UploadIsNotAllowedForSubmittedUploadFolder');
      return true;
    }
    return false;
  }

  /**
 * Check if request or self-response is rejected and show warning to not allow upload.
 * @param {boolean} uploadParentItemIsDiscarding Item is in process of rejecting/deleting
 * @param {string} uploadParentItemType Parent item type (e.g. UploadParentItemType.selfResponse)
 * @return {boolean}
 */
  public static showItemIsDiscardingWarning(uploadParentItemIsDiscarding: boolean | undefined,
    uploadParentItemType: UploadParentItemType): boolean {
    if (uploadParentItemIsDiscarding === true) {
      logger.warning(uploadParentItemType === UploadParentItemType.partnerRequest ?
        'UploadIsNotAllowedForRejectedRequest' : 'UploadIsNotAllowedForDeletedUploadFolder');
      return true;
    }
    return false;
  }

  /**
   * Check if any of the files attempting to be added excess alloved size.
   * @param {FileList} filesToAdd Native browser FileList object
   * @param {number} maxFileSize
   * @return {boolean}
   */
  public static doAnyFileExceedAllowedSize(filesToAdd: File[], maxFileSize): boolean {
    return filesToAdd.some(function (file) {
      return file.size > maxFileSize;
    });
  }

  /**
   * Checks if any FileList objects are for a folder or a zero length file (0 bytes)
   * @param {FileList} fileList Native browser FileList object
   * @return {boolean}
   * @private
   */
  public static containsZeroSizeItems(fileList: FileList): boolean {

    for (let i = 0; i < fileList.length; i++) {
      if (!fileList[i].size) {
        return true;
      }
    }

    return false;
  }

  public static isCctvFolderDuplicated(fileList: FileList, uploads: UploadModel[]): boolean {
    const filesToAdd = FileListConverter.fileListToArray(fileList);
    let folderAlreadyExist = false;

    ko.utils.arrayForEach(filesToAdd, fileToAdd => {
      if (fileToAdd.isCctv) {
        const folderName = fileToAdd.webkitRelativePath.substr(0, fileToAdd.webkitRelativePath.indexOf('/'));

        if (uploads.some(existedFile => {
          return existedFile.rootFolderName === folderName &&
            (existedFile.type === UploadType.cctvFile || existedFile.type === UploadType.deviceBackupFile ||
              existedFile.type === UploadType.generalContentFile);
        })) {
          folderAlreadyExist = true;
        }
      }
    });

    return folderAlreadyExist;
  }

  public static isCctvFolderExceedsMaximumSize(fileList: FileList, maximumAllowedSize: number): boolean {
    const filesToAdd = FileListConverter.fileListToArray(fileList);
    let folderSize = 0;
    for (const fileToAdd of filesToAdd) {
      if (fileToAdd.isCctv) {
        folderSize += fileToAdd.size;
        if (folderSize > maximumAllowedSize) {
          return true;
        }
      }
    }

    return false;
  }

  // TODO: Need to be refactored into helper
  private static getFormattedFileSize(bytes): string {

    if (bytes === 1) {
      return '1 Byte';
    }

    if (bytes < 1024) {
      return bytes + ' Bytes';
    }

    if (bytes < 1048576) {
      return RequestUploadValidation.roundDownToOneDp((bytes / 1024)) + ' KB';
    }

    if (bytes < 1073741824) {
      return RequestUploadValidation.roundDownToOneDp((bytes / 1048576)) + ' MB';
    }

    return RequestUploadValidation.roundDownToOneDp((bytes / 1073741824)) + ' GB';
  }

  private static roundDownToOneDp(number): number {
    number = number * 10;
    number = Math.floor(number);
    number = number / 10;

    return number.toFixed(1);
  }
}
