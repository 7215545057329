import axios, { AxiosRequestConfig } from 'axios';
import { ErrorCode } from '@/Types/Enums/errorCode';
import errorResponseHandler from '@/VueCore/services/clients/errorResponseHandler';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';

export default class AnonymousApiClient {

  /**
   * Returns a promise for an anonymous GET request
   * @param {string} url
   * @return {Promise}
   */
  public static async get(url: string): Promise<any> {
    try {
      const response = await axios.get(url, this.createAxiosConfig());
      return response.data;
    } catch (error: any) {
      const applicationError = errorResponseHandler.handleErrorResponse(error, []);
      throw applicationError;
    }
  }

  /**
   * Returns a promise for an anonymous POST request. Post should be used to create a new resource
   * @param {string} url
   * @param {*} data JavaScript object
   * @return {Promise}
   */
  public static async post(url: string, data: any): Promise<any> {
    try {
      const response = await axios.post(url, data, this.createAxiosConfig());
      return response.data;
    } catch (error: any) {
      const applicationError = errorResponseHandler.handleErrorResponse(error, []);
      throw applicationError;
    }
  }

  /**
   * Returns a promise for an anonymous PUT request. Put should be used to update a resource that already exists
   * @param {string} url
   * @param {*} data JavaScript object
   * @param {ErrorCode[]} [wellKnownErrorCodes] Well-known error codes that if returned should not be logged
   * @return {Promise}
   */
  public static async put(url: string, data?: any, wellKnownErrorCodes: ErrorCode[] = []): Promise<any> {
    try {
      const response = await axios.put(url, data, this.createAxiosConfig());
      return response.data;
    } catch (error: any) {
      const applicationError = errorResponseHandler.handleErrorResponse(error, wellKnownErrorCodes);
      throw applicationError;
    }
  }

  /**
   * Creates an axios config object for the given parameters to execute an anonymous request
   * @return {AxiosRequestConfig}
   */
  private static createAxiosConfig(): AxiosRequestConfig {
    const contextData = useContextDataStore();
    const config: AxiosRequestConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      // In release mode, once the SPA has loaded, expect all AJAX requests to return within 45 seconds.
      // In development, don't have a timeout to cater for debugging and long running breakpoints.
      // While the SPA is loading, "contextData.inReleaseMode" will be null until the portal settings are retrieved.
      timeout: contextData.inReleaseMode ? 45000 : undefined
    };

    const languageCode = AnonymousApiClient.getLanguageCode();

    // If the user has changed the language then set the language code to override the default browser language
    if (languageCode) {
      config.headers['Accept-Language'] = languageCode;
    }
    return config;
  }

  private static getLanguageCode(): string {
    try {
      const contextData = useContextDataStore();
      return contextData.languageCode;
    } catch (error: any) {
      return '';
    }
  }
}