import repositoryHelper from './repositoryHelper';
import DiscussionMessageModel from '@/Models/discussionMessageModel';

class DiscussionRepository {
  private static readonly baseResourceUrl = 'api/discussion';

  public static getMessagesByRequestId = async (requestId: string): Promise<DiscussionMessageModel[]> => {
    const resourceUrl = `${DiscussionRepository.baseResourceUrl}/request/${requestId}`;
    const serverDiscussionMessages = await repositoryHelper.ajaxGet(resourceUrl);
    const messages = DiscussionRepository.createDiscussionMessageModels(serverDiscussionMessages);

    return messages;
  };

  public static getMessagesByResponseId = async (responseId: string): Promise<DiscussionMessageModel[]> => {
    const resourceUrl = `${DiscussionRepository.baseResourceUrl}/response/${responseId}`;
    const serverDiscussionMessages = await repositoryHelper.ajaxGet(resourceUrl);
    const messages = DiscussionRepository.createDiscussionMessageModels(serverDiscussionMessages);

    return messages;
  };

  /**
     * Sends a new discussion to the server to process. Returns an array of "DiscussionMessageModel"
     * containing the latest version of the discussion messages.
     * @param {string} requestId
     * @param {string} message
     * @return {Promise<DiscussionMessageModel[]>}
     */
  public static sendMessageForRequest = async (requestId: string, message: string): Promise<DiscussionMessageModel[]> => {
    const newMessageData = {
      parentItemId: requestId,
      content: message
    };

    // First send the new message to the server
    await repositoryHelper.ajaxPost(`${DiscussionRepository.baseResourceUrl}/request`, newMessageData);

    // Then get all the discussion messages so all new ones are displayed
    return await DiscussionRepository.getMessagesByRequestId(requestId);
  };

  public static sendMessageForResponse = async (responseId: string, message: string): Promise<DiscussionMessageModel[]> => {
    const newMessageData = {
      parentItemId: responseId,
      content: message
    };

    // First send the new message to the server
    await repositoryHelper.ajaxPost(`${DiscussionRepository.baseResourceUrl}/response`, newMessageData);

    // Then get all the discussion messages so all new ones are displayed
    return await DiscussionRepository.getMessagesByResponseId(responseId);
  };

  private static createDiscussionMessageModels(serverDiscussionMessages: any[]): DiscussionMessageModel[] {
    return serverDiscussionMessages.map(serverDiscussionMessage =>
      new DiscussionMessageModel(
        serverDiscussionMessage.messageId,
        serverDiscussionMessage.senderDisplayName,
        serverDiscussionMessage.content,
        serverDiscussionMessage.isLocalSender,
        serverDiscussionMessage.sentTimestamp,
        serverDiscussionMessage.acknowledgedTimestamp
      )
    );
  }
}
export default DiscussionRepository;
