import $ from 'jquery';
import errorResponseHandler from './errorResponseHandler';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';

function AnonymousRepositoryHelper() {

  const self = this;

  /**
   * Returns a promise for an anonymous GET AJAX request
   * @param {string} url
   * @return {Promise}
   */
  self.ajaxGet = function (url) {
    return new Promise(function (resolve, reject) {
      $.ajax(createAjaxSetting(url)).then(resolve, function (jqXhr) {
        errorResponseHandler.handleErrorResponse(jqXhr);
        reject(jqXhr);
      });
    });
  };

  /**
   * Returns a promise for an anonymous POST AJAX request. Post should be used to create a new resource
   * @param {string} url
   * @param {*} data JavaScript object
   * @return {Promise}
   */
  self.ajaxPost = function (url, data) {

    return new Promise(function (resolve, reject) {
      $.ajax(createAjaxSetting(url, 'POST', data)).then(resolve, function (jqXhr) {
        errorResponseHandler.handleErrorResponse(jqXhr);
        reject(jqXhr);
      });
    });
  };

  /**
   * Returns a promise for an anonymous PUT AJAX request. Put should be used to update a resource that already exists
   * @param {string} url
   * @param {*} data JavaScript object
   * @param {ErrorCode[]} [errorCodesToIgnore] Well-known error codes that if returned should not be logged
   * @return {Promise}
   */
  self.ajaxPut = function (url, data, errorCodesToIgnore = []) {
    return new Promise(function (resolve, reject) {
      $.ajax(createAjaxSetting(url, 'PUT', data)).then(resolve, function (jqXhr) {
        errorResponseHandler.handleErrorResponse(jqXhr, errorCodesToIgnore);
        reject(jqXhr);
      });
    });
  };

  /**
   * Creates an jQuery AJAX setting object for the given parameters to execute and anonymous request
   * @param {string} url
   * @param {string} [type]
   * @param {*} [data]
   * @return {*}
   */
  function createAjaxSetting(url, type, data) {
    const contextData = useContextDataStore();
    const languageCode = contextData.languageCode;

    const settings = {
      url: url,
      type: type || 'GET',
      dataType: 'json'
    };

    // If the user has changed the language then set the language code to override the default browser language
    if (languageCode) {
      settings.headers = {
        'Accept-Language': languageCode
      };
    }

    if (data) {
      settings.data = JSON.stringify(data);
      settings.contentType = 'application/json';
    }

    return settings;
  }
}

export default new AnonymousRepositoryHelper();
