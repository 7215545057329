export enum LocalStorageKeyType {
  AuthenticationRedirectHash = 'authenticationRedirectHash',
  AuthenticationRegistrationId = 'authenticationRegistrationId',
  RequestsFilterCriteria = 'requestsFilterCriteria',
  UploadFoldersFilterCriteria = 'uploadFoldersFilterCriteria',
  UploadFoldersSorting = 'uploadFoldersSorting',
  RequestsSorting = 'requestsSorting',
  CamerasSorting = 'camerasSorting',
  RequestsPage = 'requestsPage',
  CamerasPage = 'camerasPage',
  UploadFoldersPage = 'uploadFoldersPage',
  PaginationRequestsPageSize = 'paginationRequestsPageSize',
  PaginationCamerasPageSize = 'paginationCamerasPageSize',
  PaginationUploadFoldersPageSize = 'PaginationUploadFoldersPageSize',
  PaginationUploadFolderUploadsPageSize = 'paginationUploadFolderUploadsPageSize',
  PaginationPartnerRequestUploadsPageSize = 'paginationPartnerRequestUploadsPageSize'
}