import apiClient from '@/VueCore/services/clients/apiClient';
import { AddressLookup } from '@/Types/addressLookup';

const ADDRESS_LOOKUP_BASE_URL = 'api/addressLookup';
export default class AddressLookupRepository {

  /**
   * @param {string} addressSearchTerm
   * @param {boolean} [orderByDistanceToCenter]
   * @return {Promise}
   */
  public static async lookupAddress(addressSearchTerm: string, orderByDistanceToCenter: boolean): Promise<AddressLookup[]> {

    orderByDistanceToCenter = (typeof orderByDistanceToCenter !== 'undefined') ? orderByDistanceToCenter : true;

    const resourceUrl =
      `${ADDRESS_LOOKUP_BASE_URL}/?addressSearchTerm=${encodeURIComponent(addressSearchTerm)}&orderByDistanceToCenter=${orderByDistanceToCenter}`;

    return apiClient.get(resourceUrl)
      .then(function (addressSearchResults) {

        return addressSearchResults.slice(0, 10);// Return the top 10 results

      });
  }
}
