import { PendingRegistration } from '@/Models/pendingRegistration';
import { PortalStartupErrorType } from '@/Types/Enums/portalStartupErrorType';
import PortalSettings from '@/Types/portalSettings';
import SupportedLanguage from '@/Types/supportedLanguage';

class ContextState {
  // Property available regardless of the SPA type loaded (portal, privacy policy or registration)
  languageCode: string;
  // Property available regardless of the SPA type loaded (portal, privacy policy or registration)
  availableLanguages: SupportedLanguage[];
  // format of date time value for calendar
  calendarDateTimeFormat: string;
  inReleaseMode: boolean;
  // Holds all the translated string from the resource file ClientMessages.resx
  resourceData: Record<string, string>;
  // UserModel - Contains user data if the Portal SPA is loaded
  userData: any;
  // Contains portal settings if the Portal SPA is loaded
  portalSettings: PortalSettings;
  // Contains registration data if the Registration SPA is loaded
  registrationData: PendingRegistration;
  // Global isLoading flag for the portal's main SPA
  isLoading: boolean;
  // Global disabling flag for Vue router path changes. It is under router control
  disableElement: boolean;
  // Global error flag for portal startup
  portalStartupError: PortalStartupErrorType;
  sessionExpiredOverlayVisible: boolean;
  constructor() {
    this.languageCode = 'en-US';
    this.availableLanguages = [];
    this.calendarDateTimeFormat = '';
    this.inReleaseMode = false;
    this.resourceData = {};
    this.userData = {};
    this.portalSettings = {} as PortalSettings;
    this.registrationData = {} as PendingRegistration;
    this.isLoading = false;
    this.disableElement = false;
    this.portalStartupError = PortalStartupErrorType.None;
    this.sessionExpiredOverlayVisible = false;
  }
}

export default ContextState;