import { App } from 'vue';
import DisableElementDirective from '@/VueCore/directives/disableElement';
import StickyElementDirective from '@/VueCore/directives/stickyElement';
import PrimeVueTooltipDirective from '@/VueCore/directives/primeVueTooltip';

export default {
  install(app: App) {
    app.directive(DisableElementDirective.name, DisableElementDirective.directive);
    app.directive(StickyElementDirective.name, StickyElementDirective.directive);
    app.directive(PrimeVueTooltipDirective.name, PrimeVueTooltipDirective.directive);
  }
};
