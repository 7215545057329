import VueDirective from '@/VueCore/directives/vueDirective';

const supportsIntersectionObserver = 'IntersectionObserver' in window;
let observer;
if (supportsIntersectionObserver) {
  observer = new IntersectionObserver(
    // If less than the whole element is displayed (which happens when the element is sticky as the first
    // pixel is hidden due to 'top: -1px') then toggle the sticky class. IntersectionObserver used for
    // performance reasons.
    ([e]) => e.target.classList.toggle('is-stuck', e.intersectionRatio < 1),
    { threshold: [1] }
  );
}

class StickyElementDirective implements VueDirective {
  name = 'sticky-element';
  directive = {
    mounted(element : HTMLElement) {
      const supportsIntersectionObserver = 'IntersectionObserver' in window;

      element.style.position = 'sticky'; // Set this element to use position sticky for modern browsers
      element.style.zIndex = '11'; // Raise the stack level so the sticky element is above other elements

      // If the browser supports IntersectionObserver then use a -1px top position to force an
      // intersection with the top of the browser when the header is sticky.
      element.style.top = supportsIntersectionObserver ? '-1px' : '0';

      if (observer) {
        observer.observe(element);
      }
    },
    unmounted(element: HTMLElement) {
      if (observer) {
        observer.unobserve(element);
      }
    }
  };
}

export default new StickyElementDirective();