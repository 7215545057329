import Request from '@/Types/request';
import repositoryHelper from '@/Repositories/repositoryHelper';

export default class RequestRepository {

  private static readonly baseResourceUrl = 'api/request/';

  public static getRequest(requestId: string): Request {

    const resourceUrl = this.baseResourceUrl + requestId;
    return repositoryHelper.ajaxGet(resourceUrl);
  }

  /**
   * @param {object} requestDataModel
   * @return {Promise}
   */
  public static updateRequest(requestDataModel) {

    const resourceUrl = this.baseResourceUrl + requestDataModel.requestId + '?submitResponse=false';
    return repositoryHelper.ajaxPut(resourceUrl, requestDataModel);
  }

  /**
   * @param {object} requestDataModel
   * @return {Promise}
   */
  public static updateRequestAndSubmitResponse(requestDataModel) {
    const resourceUrl = this.baseResourceUrl + requestDataModel.requestId + '?submitResponse=true';
    return repositoryHelper.ajaxPut(resourceUrl, requestDataModel);
  }

  /**
   * @param {string} requestId
   * @return {Promise}
   */
  public static requestHasUploadMetadata(requestId) {
    const resourceUrl = `${this.baseResourceUrl}${requestId}/hasuploadmetadata`;
    return repositoryHelper.ajaxGet(resourceUrl);
  }
}
