import { ref, Ref } from 'vue';

export default function useModalController<TModalParameters>(defaultParams?: TModalParameters) {
  const isShowing = ref<boolean>(false);
  const props: Ref<TModalParameters> = ref(defaultParams);

  function show(params?: TModalParameters) {
    isShowing.value = true;
    if (params) {
      props.value = params;
    }
  }

  function close() {
    isShowing.value = false;
  }

  return { isShowing, show, close, props };
}