import anonymousApiClient from '@/VueCore/services/clients/anonymousApiClient';
const RESOURCE_DATA_BASE_URL: string = 'api/resourceData';
export default class ResourceDataService {
  /**
   * Get resource data for the specified language code
   * @param {string} languageCode
   * @return {Promise<Record<string, string>>}
   */
  public static async getResourceData(languageCode: string): Promise<Record<string, string>> {
    const resourceUrl = `${RESOURCE_DATA_BASE_URL}/${languageCode}`;
    return anonymousApiClient.get(resourceUrl);
  }
}
