export default class UserSummaryModel {
  constructor(
    public personaId: string,
    public givenName: string,
    public familyName: string,
    public emailAddress: string,
    public isPreregistered: boolean,
    public isBusinessAdminUser: boolean,
    public isBusinessPrimaryContact: boolean
  ) {}
}