import AuthenticationSettings from '@/Types/authenticationSettings';
import localStorageHelper from '@/Utils/localStorageHelper';
import SupportedLanguage from '@/Types/supportedLanguage';
import TokenManagerSettings from '@/Models/tokenManagerSettings';

/**
 * Class to hold fixed configuration settings for the SPA. There should be minimal settings
 * here as real settings should all be retrieved from the server via an AJAX call.
 */
export default class Config {
  /** Content from resource files is wrapped in square brackets / pipe character */
  public static wrapTranslatedTextInBrackets = false;

  /** Flags whether the debug message should be displayed in the toastr notification when there's an error  */
  public static showErrorDebugMessage = false;

  public static periodTillInactiveWarningInMinutes = 15;
  public static periodTillInactiveInMinutes = 20;

  public static loggingSettings = {
    /** Flags whether debug messages are logged to the console */
    handleDebug: true
  };

  public static supportedLanguages: SupportedLanguage[] = [
    {
      resourceKey: 'LanguageNameEnglishUS',
      languageCode: 'en-US'
    },
    {
      resourceKey: 'LanguageNameEnglishGB',
      languageCode: 'en-GB'
    },
    {
      resourceKey: 'LanguageNameWelsh',
      languageCode: 'cy'
    },
    {
      resourceKey: 'LanguageNameTestLanguage',
      languageCode: 'vi-VN'
    }
  ];

  public static getTokenManagerSettingsForLogin(authenticationSettings: AuthenticationSettings): TokenManagerSettings {
    const redirectRelativePath = '/tokenLoginRedirect.html';
    return Config.getTokenManagerSettings(redirectRelativePath, authenticationSettings);
  }

  public static getTokenManagerSettingsForRegistration(authenticationSettings: AuthenticationSettings): TokenManagerSettings {
    const redirectRelativePath = '/tokenRegistrationRedirect.html';
    return Config.getTokenManagerSettings(redirectRelativePath, authenticationSettings);
  }

  private static getTokenManagerSettings(redirectRelativePath: string, authenticationSettings: AuthenticationSettings): TokenManagerSettings {
    const languageCode = localStorageHelper.getLanguageDetails().languageCode ?? authenticationSettings.defaultLanguageCode;

    console.debug(`The token manager settings 'ui_locales' property value is "${languageCode}"`);

    return new TokenManagerSettings(authenticationSettings, redirectRelativePath, languageCode);
  }
}
