import { reactive } from 'vue';

export default function useSelection<T>(items: T[]) {
  if (!items?.length) {
    throw new Error('items are not defined in useSelection');
  }

  const selectedSet = reactive<Set<T>>(new Set<T>());

  function select(item: T) {
    selectedSet.add(item);
  }

  function deselect(item: T) {
    selectedSet.delete(item);
  }

  function deselectAll() {
    selectedSet.clear();
  }

  function isSelected(item: T) {
    return selectedSet.has(item);
  }

  function selectedItems(): T[] {
    return [...selectedSet.keys()];
  }

  return { select, deselect, deselectAll, isSelected, selectedItems };
}