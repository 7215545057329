import 'core-js/stable'; // polyfills for ECMAScript up to 2020
import 'regenerator-runtime/runtime'; // Standalone runtime for Regenerator-compiled generator and async functions
import 'bootstrap-sass'; // Include Bootstrap JS

import moment from 'moment';
import logger from '@/Utils/loggerVue';
import redirectHelper from '@/Utils/redirectHelper';
import languageHelper from '@/Utils/languageHelper';
import currentUserService from '@/VueCore/services/currentUserService';
import resourceDataService from '@/VueCore/services/resourceDataService';
import systemSettingsService from '@/VueCore/services/systemSettingsService';
import { ErrorCode } from '@/Types/Enums/errorCode';
import { PortalStartupErrorType } from '@/Types/Enums/portalStartupErrorType';
import { applyDefaultVueValidationRules } from '@/Validation/validationRules';
import { ApplicationError } from '@/VueCore/services/clients/applicationError';
import TokenManagerSettings from '@/Models/tokenManagerSettings';
import PortalSettings from '@/Types/portalSettings';
import ContextState from '@/VueCore/stores/Models/contextState';

import '@/Utils/windowErrorHandler';

class PortalStartup {
  contextDataVue: ContextState;

  constructor() {
    this.contextDataVue = new ContextState();
    this.contextDataVue.isLoading = true;
  }

  async init(authTokenLanguageDetails: TokenManagerSettings): Promise<ContextState | undefined> {

    const portalStartupErrorType: PortalStartupErrorType = await this.retrieveAndPersistUserData(authTokenLanguageDetails);
    if (portalStartupErrorType !== PortalStartupErrorType.None) {

      this.contextDataVue.portalStartupError = portalStartupErrorType;
      this.contextDataVue.isLoading = false;
      return this.contextDataVue;
    }

    return this.retrieveAndPersistResourceData()
      .then(() => this.retrieveAndPersistPortalSettings(this.contextDataVue))
      .then(() => {
        this.setMomentLocale();
        applyDefaultVueValidationRules();
        this.consoleLogInfo(this.contextDataVue.portalSettings, this.contextDataVue.languageCode);

        return this.contextDataVue;
      })
      .catch((error:any) => {
        const applicationError = new ApplicationError(error);
        this.logUnexpectedError(applicationError);
        this.contextDataVue.portalStartupError = PortalStartupErrorType.UnknownError;
        this.contextDataVue.isLoading = false;
        return Promise.resolve(this.contextDataVue);
      });
  }

  private retrieveAndPersistUserData(authTokenLanguageDetails: TokenManagerSettings): Promise<PortalStartupErrorType> {
    const errorCodesToIgnore = [ErrorCode.BusinessDisabled, ErrorCode.UnknownUser];
    return currentUserService.getUserData(errorCodesToIgnore)
      .then(userData => {
        console.groupCollapsed('Authenticated user details');
        console.info('Name:', userData.displayName());
        console.info('Email address:', userData.emailAddress());
        console.info('Business:', userData.business.businessName);
        console.info('Is fully registered:', userData.isFullyRegistered);
        console.info('Can complete registration:', userData.canCompleteRegistration);
        console.groupEnd();

        // return PortalStartupErrorType.BusinessDisabled;
        if (!userData.isFullyRegistered) {
          if (userData.canCompleteRegistration) {
            console.info(
              `The user ${userData.displayName()} (${userData.emailAddress()}) is not fully registered
                so redirecting to the registration page`
            );
            redirectHelper.redirectToPath('/registration.html?id=' + userData.registrationId);
            return Promise.reject(PortalStartupErrorType.UnknownErrorGettingUserData);
          } else {
            logger.logAndThrowUntranslatedError(
              'There is a problem with your user account. Please try again later.',
              'The user with email address \'' + userData.emailAddress() + '\' is not correctly registered.');
            return Promise.reject(PortalStartupErrorType.UnknownErrorGettingUserData);
          }
        }

        const languageDetailsArray = [authTokenLanguageDetails, userData.languageDetails];
        const languageCode = languageHelper.getNewestLanguageCodeOrFirstSupported(languageDetailsArray);

        this.contextDataVue.languageCode = languageCode;
        this.contextDataVue.userData = userData;

        return PortalStartupErrorType.None;
      })
      .catch(error => {
        this.contextDataVue.isLoading = false;
        const applicationError = new ApplicationError(error);
        if (applicationError.innerError?.status === 403 && applicationError.errorData.errorCode === ErrorCode.BusinessDisabled) {
          return PortalStartupErrorType.BusinessDisabled;
        }

        if (applicationError.innerError?.status === 403 && applicationError.errorData.errorCode === ErrorCode.UnknownUser) {
          return PortalStartupErrorType.UnknownUser;
        }

        if (applicationError.innerError?.status === 401) {
          return PortalStartupErrorType.AuthorisationError;
        }

        let errorMessage = 'An error occurred loading the site. Please try again later. ';

        if (applicationError.errorData?.errorMessages) {
          errorMessage += applicationError.errorData.errorMessages.join(' ');
        }

        if (applicationError.innerError?.status === 404) {
          errorMessage += 'A user account doesn\'t exist or is incorrectly configured.';
        }

        logger.logAndThrowUntranslatedError(
          errorMessage,
          null,
          applicationError);

        return PortalStartupErrorType.UnknownErrorGettingUserData;
      });
  }

  private retrieveAndPersistResourceData(): Promise<void> {
    return resourceDataService.getResourceData(this.contextDataVue.languageCode)
      .then(resourceData => {
        this.contextDataVue.resourceData = resourceData;
      })
      .catch(error => {
        const applicationError = new ApplicationError(error);
        logger.logAndThrowUntranslatedError(
          'An error occurred loading the site. Please try again later.',
          'Unable to get the resource data / translated text',
          applicationError);

        return Promise.reject(applicationError);
      });
  }

  private retrieveAndPersistPortalSettings(contextDataVue: ContextState): Promise<void> {
    return systemSettingsService.getPortalSettings()
      .then((portalSettings: PortalSettings) => {
        contextDataVue.inReleaseMode = portalSettings.inReleaseMode;
        contextDataVue.portalSettings = portalSettings;
        contextDataVue.availableLanguages = languageHelper.getAvailableLanguages(portalSettings.availableLanguageCodes);
      })
      .catch((error: any) => {
        const applicationError = new ApplicationError(error);
        logger.logAndThrowUntranslatedError(
          'An error occurred loading the site. Please try again later.',
          'Unable to get the system portal settings',
          error);

        return Promise.reject(applicationError);
      });
  }

  private setMomentLocale(): void {
    moment.locale(this.contextDataVue.languageCode);
  }

  private consoleLogInfo(portalSettings: PortalSettings, languageCode: string): void {
    console.info('Thanks for visiting!');
    console.info('Version:', portalSettings.versionNumber);
    console.info('In release mode:', portalSettings.inReleaseMode);
    console.info('Language code:', languageCode);
  }

  private logUnexpectedError(error: ApplicationError) {
    logger.logAndThrowUntranslatedError(
      'An unexpected error occurred loading the site. Please try again later.',
      'An unexpected error occurred. Please review the error and determine if this needs to specifically be caught',
      error);
  }
}

export default new PortalStartup();
