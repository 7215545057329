import { ErrorCode } from '@/Types/Enums/errorCode';
import { UpdateUserRequest } from '@/Types/requestModels/updateUserRequest';
import { User } from '@/Types/user';
import UserModel from '@/Models/userModel';
import apiClient from '@/VueCore/services/clients/apiClient';

const CURRENT_USER_BASE_URL: string = 'api/CurrentUser';
export default class CurrentUserService {

  /**
   * @param {ErrorCode[]} wellknownErrorCodes Well-known error codes that if returned should not be logged
   * @return {Promise<unknown>}
   */
  public static async getUserData(wellknownErrorCodes: ErrorCode[]): Promise<UserModel> {
    const result = await apiClient.get(CURRENT_USER_BASE_URL, wellknownErrorCodes);
    return new UserModel(result);
  }

  public static updateUserData(userData: UpdateUserRequest): Promise<User> {
    return apiClient.put(CURRENT_USER_BASE_URL, userData);
  }

  public static updateLanguageCode(languageCode: string): Promise<User> {
    console.debug(`Saving the language code '${languageCode}' for the user`);

    const resourceUrl = `${CURRENT_USER_BASE_URL}/languageCode`;
    return apiClient.put(resourceUrl, languageCode);
  }
}