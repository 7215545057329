import axios from 'axios';
import { ErrorCode } from '@/Types/Enums/errorCode';

export class ApplicationError {
  innerError: any;
  isLogged: boolean;
  errorData: {
    errorMessages: string[];
    errorCode: ErrorCode;
    isUserRecoverable: boolean;
  };

  constructor(error: any) {
    if (error instanceof ApplicationError) {
      this.innerError = error.innerError;
      this.isLogged = error.isLogged;
      this.errorData = error.errorData;
    } else {
      this.isLogged = false;
      this.errorData = this.parseErrorData(error);
      this.innerError = error;
    }
  }

  private parseErrorData(error: any) {
    if (axios.isAxiosError(error) && typeof (error.response?.data) === 'string') {
      const parsedData = JSON.parse(error.response?.data);
      return {
        errorMessages: parsedData?.errorMessages || [],
        errorCode: parsedData?.errorCode ?? undefined,
        isUserRecoverable: parsedData?.isUserRecoverable || false
      };
    }

    return {
      errorMessages: [],
      errorCode: undefined,
      isUserRecoverable: false
    };
  }
}