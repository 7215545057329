import anonymousApiClient from '@/VueCore/services/clients/anonymousApiClient';
import apiClient from '@/VueCore/services/clients/apiClient';
import SpaSettings from '@/Types/spaSettings';
import AuthenticationSettings from '@/Types/authenticationSettings';
import PortalSettings from '@/Types/portalSettings';

const SYSTEM_SETTINGS_BASE_URL: string = 'api/systemSettings';
export default class SystemSettingsService {
  /**
   * Get privacy policy settings including release mode flag
   * @return {Promise<SpaSettings>}
   */
  public static async getPrivacyPolicySettings(): Promise<SpaSettings> {
    const resourceUrl = `${SYSTEM_SETTINGS_BASE_URL}/privacyPolicySettings`;
    return anonymousApiClient.get(resourceUrl);
  }

  /**
   * Get the URL of the Identity Server logon page for the Portal
   * @return {Promise<AuthenticationSettings>}
   */
  public static async getAuthenticationSettings(): Promise<AuthenticationSettings> {
    const resourceUrl = `${SYSTEM_SETTINGS_BASE_URL}/authenticationSettings`;
    // This Web API call is performed without a bearer token as user won't yet be authenticated
    return anonymousApiClient.get(resourceUrl);
  }

  /**
   * Get registration settings including release mode flag
   * @return {Promise}
   */
  public static async getRegistrationSettings(): Promise<any> {
    const resourceUrl = `${SYSTEM_SETTINGS_BASE_URL}/registrationSettings`;
    return anonymousApiClient.get(resourceUrl);
  }

  /**
 * Get portal settings including upload url and in release mode flag
 * @return {Promise}
 */
  public static async getPortalSettings(): Promise<PortalSettings> {

    const resourceUrl = `${SYSTEM_SETTINGS_BASE_URL}/portalSettings`;
    return apiClient.get(resourceUrl);
  }
}