export enum UploadType {
  file = 'File',
  folderFile = 'FolderFile',
  deviceBackupFile = 'DeviceBackupFile',
  cctvFile = 'CctvFile',
  deviceBackup = 'DeviceBackup',
  cctv = 'Cctv',
  zipGeneralContent = 'ZipGeneralContent',
  zipCctv = 'ZipCctv',
  zipDeviceBackup = 'ZipDeviceBackup',
  generalContentFile = 'GeneralContentFile',
  generalContent = 'GeneralContent'
}