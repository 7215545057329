export default function useSorting<T>(items: T[]) {
  const array = items;

  function sort(field: string, asc = true) {
    (array as T[]).sort((a, b) => {
      const ascMultiplier = asc ? 1 : -1;

      let aValue = a.getSortingValue(field);
      let bValue = b.getSortingValue(field);

      if (!aValue) {
        aValue = '';
      }
      if (!bValue) {
        bValue = '';
      }

      if (typeof aValue === 'string') {
        return compareStrings(aValue, bValue) * ascMultiplier;
      }
      if (aValue < bValue) {
        return -1 * ascMultiplier;
      }
      if (aValue > bValue) {
        return 1 * ascMultiplier;
      }
      if (aValue === bValue) {
        return 0;
      }
    });
  }

  return { sort };
}

function compareStrings(string1: string, string2: string) {
  return string1.localeCompare(string2, undefined, { numeric: true });
}