import AuthenticationSettings from '@/Types/authenticationSettings';

class TokenManagerSettings {
  authority: string;
  client_id: string;
  redirect_uri: string;
  post_logout_redirect_uri: string;
  response_type: string;
  scope: string;
  silent_redirect_uri: string;
  silent_renew: boolean;
  load_user_profile: boolean;
  request_state_store: Storage;
  ui_locales: string;

  constructor(authenticationSettings: AuthenticationSettings, redirectRelativePath: string, languageCode: string) {
    const protocolAndDomain = window.location.protocol + '//' + window.location.host;

    this.authority = authenticationSettings.authority;
    this.client_id = authenticationSettings.clientId;
    this.redirect_uri = protocolAndDomain + redirectRelativePath;
    this.post_logout_redirect_uri = protocolAndDomain + '/index.html';
    this.response_type = 'id_token token';
    this.scope = 'openid profile';
    this.silent_redirect_uri = protocolAndDomain + '/tokenSilentRedirect.html';
    this.silent_renew = true;
    this.load_user_profile = true;
    this.request_state_store = window.sessionStorage;
    this.ui_locales = languageCode;
  }
}

export default TokenManagerSettings;