import { RouteRecordRaw } from 'vue-router';
import UploadFoldersPage from '@/VueComponents/PageComponents/UploadFolders/UploadFoldersPage.vue';
import UploadFolderDetails from '@/VueComponents/PageComponents/UploadFolderDetails/UploadFolderDetails.vue';
import RequestsPage from '@/VueComponents/Requests/RequestsPage.vue';
import CamerasPage from '@/VueComponents/PageComponents/Cameras/CamerasPage.vue';
import CameraDetailsPage from '@/VueComponents/PageComponents/CameraDetails/CameraDetailsPage.vue';
import AddCameraPage from '@/VueComponents/PageComponents/AddCamera/AddCameraPage.vue';
import GroupsPage from '@/VueComponents/PageComponents/Groups/GroupsPage.vue';
import GroupDetails from '@/VueComponents/Groups/GroupDetails.vue';
import UsersPage from '@/VueComponents/PageComponents/Users/UsersPage.vue';
import UserDetails from '@/VueComponents/PageComponents/Users/UserDetails.vue';
import MyAccountPage from '@/VueComponents/PageComponents/MyAccount/MyAccountPage.vue';
import RequestDetailsPage from '@/VueComponents/PageComponents/RequestDetails/RequestDetailsPage.vue';
import PortalSettingsProvider from '@/Utils/portalSettingsProvider';
import { AccountType } from '@/Types/Enums/accountType';
import { PreviousRoute } from './previousRoute';
import PortalError from '@/VueComponents/PageComponents/Portal/PortalError.vue';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import selfResponseSummaryRepository from '@/Repositories/selfResponseSummaryRepository';
import selfResponseRepository from '@/Repositories/selfResponseRepository';
import requestSummaryRepository from '@/Repositories/requestSummaryRepository';
import requestRepository from '@/Repositories/requestRepository';
import associatedFileRepository from '@/Repositories/associatedFileRepository';
import discussionRepository from '@/Repositories/discussionRepository';
import businessRepository from '@/Repositories/businessRepository';
import validationRulesRepository from '@/Repositories/validationRulesRepository';
import cameraRepository from '@/Repositories/cameraRepository';
import { PortalStartupErrorType } from '@/Types/Enums/portalStartupErrorType';
import usersRepository from '@/Repositories/usersRepository';
import groupsRepository from '@/Repositories/groupsRepository';
import constants from '@/constants';

const pageMode = constants.pageMode;

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: RequestsPage,
    meta: { helpId: '52601.htm', pageTitleKey: 'Requests' },
    beforeEnter: async (to, from, next) => {
      try {
        const initialFilter = await requestSummaryRepository.getInitialFilter();
        to.params.initialFilter = initialFilter;
        const usePreviousViewSettings = from.name === 'RequestDetails';
        to.params.usePreviousViewSettings = usePreviousViewSettings;
        const previousRoute: PreviousRoute = {
          page: from.name as string | null,
          id: from.params.id as string | null
        };
        to.params.previousRoute = previousRoute;
        next();
      } catch (error) {
        console.error('Failed to fetch initial filter:', error);
        next(false);
      }
    },
    props: route => ({
      initialFilter: route.params.initialFilter,
      usePreviousViewSettings: route.params.usePreviousViewSettings,
      previousRoute: route.params.previousRoute as PreviousRoute
    })
  },
  {
    path: '/requests',
    name: 'Requests',
    component: RequestsPage,
    meta: { helpId: '52601.htm', pageTitleKey: 'Requests' },
    beforeEnter: async (to, from, next) => {
      try {
        const initialFilter = await requestSummaryRepository.getInitialFilter();
        to.params.initialFilter = initialFilter;
        const usePreviousViewSettings = from.name === 'RequestDetails';
        to.params.usePreviousViewSettings = usePreviousViewSettings;
        const previousRoute: PreviousRoute = {
          page: from.name as string | null,
          id: from.params.id as string | null
        };
        to.params.previousRoute = previousRoute;
        next();
      } catch (error) {
        console.error('Failed to fetch initial filter:', error);
        next(false);
      }
    },
    props: route => ({
      initialFilter: route.params.initialFilter,
      usePreviousViewSettings: route.params.usePreviousViewSettings,
      previousRoute: route.params.previousRoute as PreviousRoute
    })
  },
  {
    path: '/request/:id',
    name: 'RequestDetails',
    component: RequestDetailsPage,
    meta: { helpId: '52601.htm', pageTitleKey: 'RequestDetails' },
    beforeEnter: async (to, from, next) => {
      try {
        const request = await requestRepository.getRequest(to.params.id);
        const associatedFiles = await associatedFileRepository.getAssociatedFileByRequestId(to.params.id);
        const discussionMessages = await discussionRepository.getMessagesByRequestId(to.params.id);
        const requestAssignees = await businessRepository.getRequestAssigneeSummaries();

        to.params.request = request;
        to.params.associatedFiles = associatedFiles;
        to.params.discussionMessages = discussionMessages;
        to.params.requestAssignees = requestAssignees;
        next();
      } catch (error) {
        console.error('Failed to fetch request details:', error);
        next(false);
      }
    },
    props: route => ({
      request: route.params.request,
      requestId: route.params.id,
      associatedFiles: route.params.associatedFiles,
      discussionMessages: route.params.discussionMessages,
      requestAssignees: route.params.requestAssignees
    })
  },
  {
    path: '/uploadfolders',
    name: 'UploadFolders',
    component: UploadFoldersPage,
    meta: { helpId: '61542.htm', pageTitleKey: 'UploadFolders' },
    beforeEnter: async (to, from, next) => {
      if (PortalSettingsProvider.getAccountType() === AccountType.Partner && PortalSettingsProvider.getSelfResponseEnabled()) {
        try {
          const initialFilter = await selfResponseSummaryRepository.getInitialFilter();
          const usePreviousViewSettings = from.name === 'UploadFolderDetails';
          const lastAccessedResponseId = from.params.id;

          to.params.initialFilter = initialFilter;
          to.params.usePreviousViewSettings = usePreviousViewSettings;
          to.params.lastAccessedResponseId = lastAccessedResponseId;
          const previousRoute: PreviousRoute = {
            page: from.name as string | null,
            id: from.params.id as string | null
          };
          to.params.previousRoute = previousRoute;

          next();
        } catch (error) {
          console.error('Failed to fetch initial filter:', error);
          next(false);
        }
      } else {
        next('/');
      }
    },
    props: route => ({
      initialFilter: route.params.initialFilter,
      usePreviousViewSettings: route.params.usePreviousViewSettings,
      lastAccessedResponseId: route.params.lastAccessedResponseId || null
    })
  },
  {
    path: '/uploadfolderdetails/:id',
    name: 'UploadFolderDetails',
    component: UploadFolderDetails,
    meta: { helpId: '61545.htm', pageTitleKey: 'SelfResponseDetails' },
    beforeEnter: async (to, from, next) => {
      try {
        const response = await selfResponseRepository.getResponse(to.params.id);
        const responseAssignees = await businessRepository.getRequestAssigneeSummaries();
        const discussionMessages = await discussionRepository.getMessagesByResponseId(to.params.id);

        to.params.response = response;
        to.params.responseId = to.params.id;
        to.params.responseAssignees = responseAssignees;
        to.params.discussionMessages = discussionMessages;

        next();
      } catch (error) {
        console.error('Failed to fetch upload folder details:', error);
        next(false);
      }
    },
    props: route => ({
      response: route.params.response,
      responseId: route.params.responseId,
      responseAssignees: route.params.responseAssignees,
      discussionMessages: route.params.discussionMessages
    })
  },
  {
    path: '/cameras',
    name: 'Cameras',
    component: CamerasPage,
    meta: { helpId: '52604.htm', pageTitleKey: 'CamerasTitle' },
    beforeEnter: async (to, from, next) => {
      if (PortalSettingsProvider.getAccountType() === AccountType.Business) {
        try {
          const usePreviousViewSettings = from.name === 'AddCamera' || from.name === 'EditCamera';
          const allCameras = await businessRepository.getCameras();

          to.params.usePreviousViewSettings = usePreviousViewSettings;
          to.params.allCameras = allCameras;

          const previousRoute: PreviousRoute = {
            page: from.name as string | null,
            id: from.params.id as string | null
          };

          to.params.previousRoute = previousRoute;

          next();
        } catch (error) {
          console.error('Failed to fetch cameras:', error);
          next(false);
        }
      } else {
        next('/');
      }
    },
    props: route => ({
      usePreviousViewSettings: route.params.usePreviousViewSettings,
      allCameras: route.params.allCameras,
      previousRoute: route.params.previousRoute
    })
  },
  {
    path: '/cameras/new',
    name: 'AddCamera',
    component: AddCameraPage,
    meta: { helpId: '52605.htm', pageTitleKey: 'AddCameraTitle' },
    beforeEnter: async (to, from, next) => {
      try {
        const validationRules = await validationRulesRepository.getValidationRules('camera');
        to.params.validationRules = validationRules;
        next();
      } catch (error) {
        console.error('Failed to fetch validation rules:', error);
        next(false);
      }
    },
    props: route => ({
      validationRules: route.params.validationRules
    })
  },
  {
    path: '/cameras/:id',
    name: 'EditCamera',
    component: CameraDetailsPage,
    meta: { helpId: '52639.htm', pageTitleKey: 'EditCameraTitle' },
    beforeEnter: async (to, from, next) => {
      try {
        const camera = await cameraRepository.getCamera(to.params.id);
        to.params.camera = camera;
        next();
      } catch (error) {
        console.error('Failed to fetch camera details:', error);
        next(false);
      }
    },
    props: route => ({
      camera: route.params.camera
    })
  },
  {
    path: '/users',
    name: 'Users',
    component: UsersPage,
    meta: { helpId: '57804.htm', pageTitleKey: 'UsersTitle' },
    beforeEnter: async (to, from, next) => {
      try {
        const contextData = useContextDataStore();
        const allUsers = await usersRepository.getAllUsers(contextData.userData.business.businessId);
        const validationRules = await validationRulesRepository.getValidationRules('businessuserupdate');

        to.params.allUsers = allUsers;
        to.params.validationRules = validationRules;
        next();
      } catch (error) {
        console.error('Failed to fetch users or validation rules:', error);
        next(false);
      }
    },
    props: route => ({
      allUsers: route.params.allUsers,
      validationRules: route.params.validationRules,
      pageMode: pageMode.edit
    })
  },
  {
    path: '/users/new',
    name: 'AddUser',
    component: UserDetails,
    meta: { helpId: '57809.htm', pageTitleKey: 'AddUserTitle' },
    beforeEnter: async (to, from, next) => {
      try {
        const validationRules = await validationRulesRepository.getValidationRules('businessusercreate');
        to.params.validationRules = validationRules;
        next();
      } catch (error) {
        console.error('Failed to fetch validation rules:', error);
        next(false);
      }
    },
    props: route => ({
      validationRules: route.params.validationRules,
      pageMode: pageMode.add
    })
  },
  {
    path: '/groups',
    name: 'Groups',
    component: GroupsPage,
    meta: { helpId: '57806.htm', pageTitleKey: 'GroupsTitle' },
    beforeEnter: async (to, from, next) => {
      try {
        const contextData = useContextDataStore();
        const allGroups = await groupsRepository.getAllGroups(contextData.userData.business.businessId);
        const validationRules = await validationRulesRepository.getValidationRules('group');

        to.params.allGroups = allGroups;
        to.params.validationRules = validationRules;
        next();
      } catch (error) {
        console.error('Failed to fetch groups or validation rules:', error);
        next(false);
      }
    },
    props: route => ({
      allGroups: route.params.allGroups,
      validationRules: route.params.validationRules
    })
  },
  {
    path: '/groups/new',
    name: 'AddGroup',
    component: GroupDetails,
    meta: { helpId: '57817.htm', pageTitleKey: 'AddGroupTitle' },
    beforeEnter: async (to, from, next) => {
      try {
        const contextData = useContextDataStore();
        const validationRules = await validationRulesRepository.getValidationRules('group');
        const allPersonas = await usersRepository.getAllPersonaSummaries(contextData.userData.business.businessId);

        to.params.validationRules = validationRules;
        to.params.allPersonas = allPersonas;
        next();
      } catch (error) {
        console.error('Failed to fetch validation rules or personas:', error);
        next(false);
      }
    },
    props: route => ({
      validationRules: route.params.validationRules,
      allPersonas: route.params.allPersonas,
      pageMode: pageMode.add
    })
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccountPage,
    meta: { helpId: '52772.htm', pageTitleKey: 'MyAccount' },
    beforeEnter: async (to, from, next) => {
      try {
        const userDetailsValidation = await validationRulesRepository.getValidationRules('user');
        const accountDetailsValidation = await validationRulesRepository.getValidationRules('account');

        to.params.userDetailsValidation = userDetailsValidation;
        to.params.accountDetailsValidation = accountDetailsValidation;
        next();
      } catch (error) {
        console.error('Failed to fetch validation rules:', error);
        next(false);
      }
    },
    props: route => ({
      userDetailsValidation: route.params.userDetailsValidation,
      accountDetailsValidation: route.params.accountDetailsValidation
    })
  },
  {
    path: '/error',
    name: 'PortalError',
    component: PortalError,
    meta: { helpId: '52601.htm', pageTitleKey: 'PortalError' },
    beforeEnter: (to, from, next) => {
      const contextData = useContextDataStore();
      if (contextData.portalStartupError === PortalStartupErrorType.None) {
        next('/');
      } else {
        next();
      }
    }

  }
];

export default routes;

