import anonymousApiClient from '@/VueCore/services/clients/anonymousApiClient';
import apiClient from '@/VueCore/services/clients/apiClient';
import { ApplicationError } from '@/VueCore/services/clients/applicationError';
import Upload from '@/Types/upload';
import UpdateUploadModel from '@/Types/uploadRepositoryModels/updateUploadModel';
import constants from '@/constants';
import uploadManager from '@/Utils/uploadManager';
import UploadModel from '@/Models/uploadModel';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';
import logger from '@/Utils/loggerVue';
import GetUploadMetadataRequest from '@/VueComponents/Uploads/models/getUploadMetadataRequest';
import GetUploadMetadataResponse from '@/VueComponents/Uploads/models/getUploadMetadataResponse';
import SaveUploadMetadataRequest from '@/VueComponents/Uploads/models/saveUploadMetadataRequest';
import moment from 'moment/moment';
import UploadTypeResolver from '@/Utils/uploadTypeResolver';
import BasePartnerUploadDataModel from '@/Models/basePartnerUploadDataModel';
import FileUploadModel from '@/VueComponents/FileUploadSelector/models/fileUploadModel';

const UPLOAD_BASE_URL: string = 'api/upload';

// TODO: remove all the ko 'UploadModel' dependencies once uploadManager migrated to Vue.js
export default class UploadRepository {

  public static getUploadsByRequestId(requestId: string): Promise<UploadModel[]> {

    const resourceUrl = `${UPLOAD_BASE_URL}/?requestId=${requestId}`;

    return new Promise(function (resolve, reject) {

      apiClient.get(resourceUrl)
        .then(function (serverUploads) {
          const uploads = UploadRepository.createUploadModels(serverUploads);
          resolve(uploads);
        })
        .catch((error: ApplicationError) => {
          reject(error);
        });
    });
  }

  public static getUploadsByResponseId(responseId: string): Promise<Upload[]> {

    const resourceUrl = `${UPLOAD_BASE_URL}/response/?responseId=${responseId}`;

    return new Promise(function (resolve, reject) {

      apiClient.get(resourceUrl)
        .then(function (serverUploads) {
          const uploads = UploadRepository.createUploadModels(serverUploads);
          resolve(uploads);
        })
        .catch((error: ApplicationError) => {
          reject(error);
        });
    });
  }

  public static updateUploads(uploads: UpdateUploadModel[]): Promise<void> {

    if (uploads.length === 0) {
      return Promise.resolve();
    }

    const resourceUrl = `${UPLOAD_BASE_URL}/updateItems`;

    return apiClient.put(resourceUrl, uploads);
  }

  public static deleteUploads(uploads: BasePartnerUploadDataModel[]): Promise<void> {

    const resourceUrl = `${UPLOAD_BASE_URL}/deleteItems`;
    const uploadModels = uploads.flatMap(u => u.getUploadModels());

    uploadModels.forEach(upload => {
      if (upload.statusName() === constants.uploadStatuses.pending ||
        upload.statusName() === constants.uploadStatuses.uploading) {

        uploadManager.cancelUpload(upload);
      }
    });

    // If the upload failed authorisation then there is no upload to delete.
    const uploadsForDelete = uploadModels.filter(upload => {
      return upload.statusName() !== constants.uploadStatuses.failedAuthorisation;
    }).map(function (upload) {
      return upload.uploadId;
    });

    // If there is no upload to delete so return with a resolved promise.
    // For uploads that are authorising the user can't remove the upload.
    if (uploadsForDelete.length === 0) {
      return Promise.resolve();
    }

    return apiClient.post(resourceUrl, uploadsForDelete);
  }

  public static deleteUploadsObsolete(uploads: UploadModel[]): Promise<void> {

    const resourceUrl = `${UPLOAD_BASE_URL}/deleteItems`;

    uploads.forEach(upload => {
      if (upload.statusName() === constants.uploadStatuses.pending ||
        upload.statusName() === constants.uploadStatuses.uploading) {

        uploadManager.cancelUpload(upload);
      }
    });

    // If the upload failed authorisation then there is no upload to delete.
    const uploadsForDelete = uploads.filter(upload => {
      return upload.statusName() !== constants.uploadStatuses.failedAuthorisation;
    }).map(function (upload) {
      return upload.uploadId;
    });

    // If there is no upload to delete so return with a resolved promise.
    // For uploads that are authorising the user can't remove the upload.
    if (uploadsForDelete.length === 0) {
      return Promise.resolve();
    }

    return apiClient.post(resourceUrl, uploadsForDelete);
  }

  // TODO: Remove when partner request upload file selector is reworked to use new generic FileSelector components
  public static addUploadsAsFiles(fileList: FileList | File[], parentItemId: string,
    parentItemType: UploadParentItemType,
    addUploadsFailedCallback: (e: any) => void) {

    let i;
    let file;
    let upload;
    const serverUpload = null;
    const files = [];
    const uploads = [];

    for (i = 0; i < fileList.length; i++) {
      file = fileList[i];
      files.push(file);

      // ReSharper disable once ExpressionIsAlwaysConst
      upload = new UploadModel(serverUpload, file);
      UploadRepository.subscribeToFailedUploadStatusName(upload);
      uploads.push(upload);
    }

    // Asynchronously create each upload on the server / generate upload token then start the uploads
    UploadRepository.postNewUploadsToServerAsFiles(files, parentItemId, parentItemType)
      .then(function (serverUploads) {
        if (parentItemType === UploadParentItemType.businessRequest) {
          UploadRepository.startUploads(uploads, serverUploads);
        } else {
          // update model from server response
          for (let i = 0; i < serverUploads.length; i++) {
            const serverUpload = serverUploads[i];
            const upload = uploads[i];
            upload.updateAfterServerModelCreated(serverUpload);
            upload.setInitializingStatus();
          }
          UploadRepository.initMetadata(serverUploads, parentItemId, parentItemType)
            .catch((error: ApplicationError) => {
              if (error.errorData?.errorMessages?.length) {
                logger.untranslatedWarning(error.errorData?.errorMessages[0]);
              } else {
                logger.warning('FailedToInitUploadMetadata');
              }
            })
            .finally(() => {
              UploadRepository.startUploads(uploads, serverUploads);
            });
        }
      })
      .catch(function (e) {

        let upload;

        for (i = 0; i < uploads.length; i++) {
          upload = uploads[i];
          upload.setUploadFailedAuthorisation();
        }

        addUploadsFailedCallback(e);
      });

    return uploads;
  }

  public static addUploads(files: FileUploadModel[], parentItemId: string,
    parentItemType: UploadParentItemType,
    addUploadsFailedCallback: (e: any) => void): UploadModel[] {

    const uploads = [];

    for (const file of files) {
      // ReSharper disable once ExpressionIsAlwaysConst
      const upload = new UploadModel(null, null, file);
      UploadRepository.subscribeToFailedUploadStatusName(upload);
      uploads.push(upload);
    }

    // Asynchronously create each upload on the server / generate upload token then start the uploads
    UploadRepository.postNewUploadsToServer(files, parentItemId, parentItemType)
      .then(function (serverUploads) {
        if (parentItemType === UploadParentItemType.businessRequest) {
          UploadRepository.startUploads(uploads, serverUploads);
        } else {
          // update model from server response
          for (let i = 0; i < serverUploads.length; i++) {
            const serverUpload = serverUploads[i];
            const upload = uploads[i];
            upload.updateAfterServerModelCreated(serverUpload);
            upload.setInitializingStatus();
          }
          UploadRepository.initMetadata(serverUploads, parentItemId, parentItemType)
            .catch((error: ApplicationError) => {
              if (error.errorData?.errorMessages?.length) {
                logger.untranslatedWarning(error.errorData?.errorMessages[0]);
              } else {
                logger.warning('FailedToInitUploadMetadata');
              }
            })
            .finally(() => {
              UploadRepository.startUploads(uploads, serverUploads);
            });
        }
      })
      .catch(function (e) {

        for (const upload of uploads) {
          upload.setUploadFailedAuthorisation();
        }

        addUploadsFailedCallback(e);
      });

    return uploads;
  }

  public static deleteUpload(upload: UploadModel): Promise<void> {

    const resourceUrl = `${UPLOAD_BASE_URL}/deleteItem/${upload.uploadId}`;

    if (upload.statusName() === constants.uploadStatuses.pending ||
      upload.statusName() === constants.uploadStatuses.uploading) {

      uploadManager.cancelUpload(upload);
    }

    // If the upload failed authorisation then there is no upload to delete so return with a resolved
    // promise. For uploads that are authorising the user can't remove the upload.
    if (upload.statusName() === constants.uploadStatuses.failedAuthorisation) {
      return Promise.resolve();
    }

    return apiClient.delete(resourceUrl);
  }

  public static setUploadFailed(uploadToken: string): Promise<void> {
    const uri = `${UPLOAD_BASE_URL}/setFailed/${uploadToken}`;
    return apiClient.put(uri);
  }

  public static uploadComplete(uploadToken: string): Promise<void> {
    const resourceUrl = `${UPLOAD_BASE_URL}/complete/${uploadToken}`;
    return anonymousApiClient.put(resourceUrl);
  }

  public static getUploadMetadataAndConfigurableFields(model: GetUploadMetadataRequest,
    uploadParentItemType: UploadParentItemType): Promise<GetUploadMetadataResponse> {
    const parentItemType = uploadParentItemType === UploadParentItemType.selfResponse ?
      'response' : 'request';
    const resourceUrl = `${UPLOAD_BASE_URL}/${parentItemType}/metadata`;
    return apiClient.post(resourceUrl, model);
  }

  public static saveUploadsMetadata(model: SaveUploadMetadataRequest,
    uploadParentItemType: UploadParentItemType): Promise<{ deletedConfigurableFieldNames: string[] }> {
    const parentItemType = uploadParentItemType === UploadParentItemType.selfResponse ?
      'response' : 'request';
    const resourceUrl = `${UPLOAD_BASE_URL}/${parentItemType}/metadata`;
    return apiClient.put(resourceUrl, model);
  }

  private static startUploads(uploads: UploadModel[], serverUploads: Upload[]) {
    let serverUpload;
    let upload;

    for (let i = 0; i < serverUploads.length; i++) {
      serverUpload = serverUploads[i];
      upload = uploads[i];

      upload.updateAfterServerModelCreated(serverUpload);
      uploadManager.addUpload(upload);
    }
  }

  private static postNewUploadsToServerAsFiles(files: FileList | File[],
    requestId: string,
    uploadParentItemType: UploadParentItemType) {
    const resourceUrl = this.getNewUploadsUrl(uploadParentItemType);

    const data = {
      parentItemId: requestId,
      uploads: files.map(function (file) {
        return {
          fileName: file.name,
          fileSize: file.size,
          lastWriteDate: moment(file.lastModified || file.lastModifiedDate).toISOString(),
          fileRelativePath: file.webkitRelativePath,
          type: UploadTypeResolver.getTypeFromNativeFile(file)
        };
      })
    };

    return apiClient.post(resourceUrl, data);
  }

  private static postNewUploadsToServer(files: FileUploadModel[],
    requestId: string,
    uploadParentItemType: UploadParentItemType) {
    const resourceUrl = this.getNewUploadsUrl(uploadParentItemType);

    const data = {
      parentItemId: requestId,
      uploads: files.map(function (fileModel) {
        return {
          fileName: fileModel.name,
          fileSize: fileModel.file.size,
          lastWriteDate: moment(fileModel.file.lastModified || fileModel.file.lastModifiedDate).toISOString(),
          fileRelativePath: fileModel.path,
          type: UploadTypeResolver.getFromFileUploadType(fileModel.type)
        };
      })
    };

    return apiClient.post(resourceUrl, data);
  }

  private static getNewUploadsUrl(uploadParentItemType: UploadParentItemType): string {
    const parentItemType = uploadParentItemType === UploadParentItemType.selfResponse ?
      'response' : 'request';
    return `${UPLOAD_BASE_URL}/uploadItems/${parentItemType}`;
  }

  private static createUploadModels(serverUploads: Upload[]): UploadModel[] {

    let serverUpload;
    const uploads = [];

    for (let i = 0; i < serverUploads.length; i++) {
      serverUpload = serverUploads[i];
      uploads.push(UploadRepository.getOrCreateUploadModel(serverUpload));
    }

    return uploads;
  }

  private static getOrCreateUploadModel(serverUpload) {
    const upload = uploadManager.getUpload(serverUpload.uploadId);
    return upload ? upload : new UploadModel(serverUpload);
  }

  private static subscribeToFailedUploadStatusName(upload) {
    upload.statusName.subscribe(value => {
      if (value === constants.uploadStatuses.failed) {
        UploadRepository.setUploadFailed(upload.uploadToken);
      }
    });
  }

  private static initMetadata(serverUploads, parentItemId, uploadParentItemType): Promise<void> {
    const parentItemType = uploadParentItemType === UploadParentItemType.selfResponse ?
      'response' : 'request';
    const resourceUrl = `${UPLOAD_BASE_URL}/${parentItemType}/metadata/init`;

    const data = {
      parentId: parentItemId,
      uploads: serverUploads.map(file => {
        return {
          id: file.uploadId,
          name: file.fileName,
          type: file.type
        };
      })
    };

    return apiClient.post(resourceUrl, data);
  }
}